import React, { useEffect, useRef } from 'react';
import ProfileItem from './ProfileItem'
import { Height } from '../util/ScreenHeight'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { getId } from '../util/ID'
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Adsense } from '@ctrl/react-adsense';
import { getNoAd } from '../util/ID'

export default function ProfileContainer(props) {
    const [page, setPage] = React.useState(1);
    // const [sort, setSort] = React.useState(0);
    // const [count, setCount] = React.useState(50);
    const [total, setTotal] = React.useState(100)

    const [showEmpty, setShowEmpty] = React.useState(false);
    const [emptyMsg, setEmptyMsg] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [tp, setTp] = React.useState(0)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const fetchData = () => {
        setLoading(true)

        let param = {
            sn: getId()
        }

        fetch("/osc/v1/stock2/mmoo/profile", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null) {
                        if (result.data != null && result.data.length > 0) {
                            setResult(result.data)
                        } else {
                            setShowEmpty(true)
                            setEmptyMsg("目前尚未找到符合資料。")
                        }
                    }
                }
            );
    }

    useEffect(() => {
        fetchData()
    }, []);



    return (
        <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
            {loading ? <Box sx={{ position: 'absolute', top: Height.header - 2, width: '100%' }}><LinearProgress color="info" /></Box> : <Grid container spacing={0} sx={{ p: 0.5, width: '100%', bgcolor: 'background.paper', position: 'absolute', top: Height.header, bottom: fullScreen ? 60 : 10, overflowY: 'scroll' }}>

                {getNoAd() === "0" && <Grid key={"ad"} item xs={12} sm={12} md={12} lg={12}>
                    <Adsense
                        client="ca-pub-8613274553211553"
                        slot="5061770666"
                        style={{ display: 'block' }}
                        layout="in-article"
                        format="fluid"
                    />
                </Grid>}

                {result != null && result.map(row => {
                    return <Grid key={row.s.id} item xs={12} sm={6} md={4} lg={3}>
                        <ProfileItem row={row} goToOSCPage={props.goToOSCPage} />
                    </Grid>
                })}
            </Grid>}


        </Box>
    );
}
