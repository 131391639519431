import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import Favorite from '@mui/icons-material/Favorite'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Stack from '@mui/material/Stack';
import { getId } from '../util/ID'
import Divider from '@mui/material/Divider';



const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function StockHeader(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [result, setResult] = React.useState(null);

    const getColor = () => {
        if (result != null && result.p != null) {
            if (result.p.i > 0) return "red"
            else if (result.p.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (result != null && result.p != null) {
            if (result.p.i > 0) return "▲" + result.p.i + '元'
            else if (result.p.i < 0) return "▼" + result.p.i + '元'
            else return result.p.i + '元'
        } else return result.p.i + '元'
    }

    const clickLove = () => {
        let param = {
            sn: getId(),
            sid: props.sid,
            tp: props.tp,
            froms: props.menu
        }

        if(localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }
        
        fetch("/osc/v1/stock2/mmoo/clicklove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        getPriceInfo()
                        if (props.updateLove != null) props.updateLove(props.sid, props.tp)
                    }
                }
            );
    }


    const getPriceInfo = () => {
        let param = {
            sn: getId(),
            sid: props.sid,
        }

        fetch("/osc/v1/stock2/mmoo/price", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if(result != null) {
                        setResult(result)
                    }
                }
            );
    }

    useEffect(() => {
        if (props.sid != null) getPriceInfo()
    }, [props.sid, props.menu, props.tp]);

    return (
        <div>
            {result != null && <Box display={'flex'}>
                <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 1, pt: 0.5, alignSelf: 'center' }} >
                    {result.islv ? <Favorite color="error" onClick={e => clickLove()} /> :
                        <FavoriteBorderOutlined onClick={e => clickLove()} />}

                    <Typography sx={{ fontSize: 30, fontWeight: 'bold', alignSelf: 'center' }}>{result.p.n}</Typography>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{props.sid}</Typography>
                </Stack>
                <Stack direction='row' spacing={0.5} sx={{ p: 1, alignSelf: 'center' }}>
                    <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{result.p.p}元</Typography>
                    <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                </Stack>
            </Box>}
            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 1,
                    margin: 0,
                }}
            />
        </div>
    );
}

