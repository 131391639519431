import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart, ScatterChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';
// import StockHeader from './StockHeader';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { getId } from '../util/ID'

echarts.use(
    [ScatterChart, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));


export default function FullProfileChart(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    function genoption1() {
        let buymark = result.buy.map(v => { return { symbolRotate: -180, symbolOffset: [0, 0], coord: [v.x, v.y], itemStyle: { color: "#d41c1c" }, } })
        let sellmark = result.sell.map(v => { return { symbolOffset: [0, 0], coord: [v.x, v.y], itemStyle: { color: "#65b206" }, } })
        // let restmark = result.nowb.map(v => { return { symbolRotate: -180, symbolOffset: [0, -18], coord: [v.ymd, v.price], itemStyle: { color: "#d41c1c" }, } })

        let k = {
            animation: false,
            title: [
                {
                    top: '30',
                    text: '加權指數',
                }, {
                    top: '330',
                    text: '240日盈虧'
                },
            ],
            grid: [{
                top: 70,
                left: 0,
                right: 5,
                height: 250,
                containLabel: true
            },
            {
                top: 370,
                left: 0,
                right: 5,
                height: 100,
                containLabel: true
            }],
            legend: {
                data: ['加權指數', '盈虧', '20日平均盈虧'],
                right: 0,
                icon: 'roundRect',
                formatter: function (name) {
                    if (name === "盈虧" || name === "20日平均盈虧") {
                        if (result.data[result.data.length - 1].p != null) {

                            let value = result.data[result.data.length - 1].p.money
                            if (name === '20日平均盈虧') {
                                value = result.data[result.data.length - 1].p.avgm
                            }
                            return name + " : " + value
                        } else return name
                    } else return name
                }
            },
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                }
            }, {
                gridIndex: 1,
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                }
            }],
            series: [
                {
                    name: '加權指數',
                    type: 'line',
                    data: result.data.map(v => { return v.price }),
                    selectedMode: 'single',
                    markPoint: {
                        symbolSize: 12,
                        symbol: 'circle',
                        data: buymark.concat(sellmark),
                    }
                },
                {
                    name: '盈虧',
                    type: 'line',
                    data: result.data.map(v => {
                        if (v.p != null) return v.p.money
                        else return null
                    }),
                    smooth: true,
                    showSymbol: false,
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                },
                {
                    name: '20日平均盈虧',
                    type: 'line',
                    data: result.data.map(v => {
                        if (v.p != null) return v.p.avgm
                        else return null
                    }),
                    smooth: true,
                    showSymbol: false,
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                }
            ]
        };

        let x1 = {
            type: 'category',
            boundaryGap: false,
            data: result.data.map(v => v.ymd)
        }

        let x2 = {
            gridIndex: 1,
            type: 'category',
            boundaryGap: false,
            data: result.data.map(v => v.ymd),
        }

        if (fullScreen) {
            k['tooltip'] = getMobileTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getMobileDataZoom(80, 100)

            x1['axisPointer'] = {
                show: true
            }

            x2['axisPointer'] = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    size: 22
                }
            }
        } else {
            k['tooltip'] = getComputerTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getComputerDataZoom(50, 100, 470)
        }

        k['xAxis'] = [x1, x2]
        return k;
    }

    const fetchData = () => {
        let param = {
            sn: getId(),
            id: props.id,
        }

        fetch("/osc/v1/stock2/mmoo/fullprofile", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        if (result.status === 0) {
                            setResult(result)
                        }

                    }
                }
            );
    }

    useEffect(() => {
        if (result != null) setOption(genoption1)
    }, [result]);

    useEffect(() => {
        if (props.id != null) fetchData()
    }, [props.id]);


    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            {/* <DialogTitle sx={{ p: 0 }}>
                <StockHeader updateLove={props.updateLove} menu={props.menu} sid={props.mmoo.mmoo.sid} tp={props.tp} />
            </DialogTitle> */}
            <DialogContent sx={{ p: 1, mt: 1, mb: 1 }}>
                {result != null && result.s != null && <div>
                    <Typography variant='h5' sx={{ alignSelf: 'center', mt: 0, fontWeight:'bold' }}>{result.s.name}</Typography>
                    {/* <Typography variant='h4' sx={{ alignSelf: 'center', mt: 0.5 }}>進出策略</Typography>
                    <Stack direction="row">
                        <Typography variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center', whiteSpace: 'nowrap' }} >進場：</Typography>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{result.s.myrule.desc}</Typography>
                    </Stack>

                    <Stack direction="row">
                        <Typography variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center', whiteSpace: 'nowrap' }} >出場：</Typography>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{result.s.outmyrule.desc}</Typography>
                    </Stack> */}

                    <Stack direction="row" sx={{ ml: 0 }}>
                        <Typography variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', whiteSpace: 'nowrap' }} >進場條件：</Typography>
                        <Stack direction="column">
                            {result.s.myrule != null && result.s.myrule.desc != null && result.s.myrule.desc.map(row => {
                                return <Typography variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'left' }} >{row}</Typography>
                            })}
                        </Stack>
                    </Stack>

                    <Stack direction="row" sx={{ ml: 0 }}>
                        <Typography variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', whiteSpace: 'nowrap' }} >出場條件：</Typography>
                        <Stack direction="column">
                            {result.s != null && result.s.outmyrule != null && result.s.outmyrule.desc.map(row => {
                                return <Typography variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'left' }} >{row}</Typography>
                            })}
                            {result.s != null && result.s.outmyrule1 != null && result.s.outmyrule1.desc.map(row => {
                                return <Typography variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'left' }} >{row}</Typography>
                            })}
                        </Stack>
                    </Stack>

                </div>}

                <Stack direction="row" spacing={2}>
                    {/* <Typography variant='h4' sx={{ alignSelf: 'center', mt: 0.5 }}>交易時機</Typography> */}
                    <Stack direction="row">
                        <Typography sx={{ fontSize: 20, color: "#d41c1c", fontWeight: 'bold', alignSelf: 'center' }} >●</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', whiteSpace: 'nowrap' }} >：開始交易</Typography>
                    </Stack>

                    <Stack direction="row">
                        <Typography sx={{ fontSize: 20, color: "#65b206", fontWeight: 'bold', alignSelf: 'center' }} >●</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', whiteSpace: 'nowrap' }} >：暫不交易</Typography>
                    </Stack>
                </Stack>

                {option != null ?
                    <ReactEChartsCore
                        theme={theme.palette.mode}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 550 }}
                    />
                    : null}

                {/* {result != null && <BuySellTable nowb={result.nowb} bs={result.bs} />} */}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="info" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}

