


export const getMobileTooltip = (upTop) => {
    let upT = upTop == null ? 350 : upTop
    return {
        triggerOn: 'none',
        transitionDuration: 0,
        confine: true,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        position: function (pos, params, el, elRect, size) {
            var obj = { top: pos[1] - upT };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
            return obj;
        },
        extraCssText: 'width: 170px',
        bordeRadius: 4,
        borderWidth: 1,
        borderColor: '#333',
        order: 'seriesAsc'
    }
}


export const getComputerTooltip = (upTop) => {
    let upT = upTop == null ? 350 : upTop

    return {
        transitionDuration: 0,
        confine: true,
        trigger: 'axis',
        axisPointer: {
            type: 'cross'
        },
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        position: function (pos, params, el, elRect, size) {
            var obj = { top: pos[1] - upT };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
            return obj;
        },
        textStyle: {
            fontSize: 12
        },
        extraCssText: 'width: 170px',
        bordeRadius: 4,
        borderWidth: 1,
        borderColor: '#333',
        order: 'seriesAsc'
    }
}


export const getAxisPointer = () => {
    return {
        link: { xAxisIndex: 'all' },
        label: {
            backgroundColor: '#777'
        }
    }
}


export const getMobileDataZoom = (zoStart, zoEnd, isDisable) => {
    return [
        {
            type: 'inside',
            xAxisIndex: 'all',
            start: zoStart,
            end: zoEnd,
            disabled: isDisable
        }
    ]
}


export const getComputerDataZoom = (zoStart, zoEnd, top, zoomLock) => {
    return [
        {
            type: 'inside',
            xAxisIndex: 'all',
            start: zoStart,
            end: zoEnd,
            disabled: zoomLock
        },
        // {
        //     show: true,
        //     xAxisIndex: 'all',
        //     type: 'slider',
        //     top: top,
        //     start: zoStart,
        //     end: zoEnd,
        //     zoomLock: zoomLock
        // }
    ]
}