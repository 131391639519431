import React from 'react'
import { BrowserRouter as Router, Route, HashRouter, Routes } from 'react-router-dom'

import MobileHome from './mhome/Home'
import CHome from './chome/CHome'
import CHomeM1 from './mhome1/CHome'
import IosHome from './ioshome/CHome'

export default props => (   
      <HashRouter>
        <Routes>
          <Route exact path='/' element={ <CHome/> } />          
          <Route exact path='/m' element={ <MobileHome/> } />
          <Route exact path='/gool' element={ <CHomeM1/> } />
          <Route exact path='/ios' element={ <IosHome/> } />
          <Route exact path='/open' element={ <CHome/> } />          
        </Routes>
      </HashRouter>
  )