import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import KDChart from '../pic/KDChart'
import Button from '@mui/material/Button';
import { Lightbulb } from '@mui/icons-material';
import MACDChart from '../pic/MACDChart'
import AvgChart from '../pic/AvgChart'
import RSIChart from '../pic/RSIChart'
import DMIChart from '../pic/DMIChart'
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import Favorite from '@mui/icons-material/Favorite'
import { getId } from '../util/ID'
import SARChart from '../pic/SARChart';
import PressureChart from '../pic/PressureChart'
import HPLChart from '../pic/HLPChart';
import Avg520Chart from '../pic/Avg520Chart';
import Avg6020Chart from '../pic/Avg6020Chart'
import BollingerChart from '../pic/BollingerChart';
import CantChart from '../pic/CantChart';
import Detail from '../detail/Detail';
import BrokerChart from '../pic/BrokerChart'
import AlligatorChart from '../pic/AlligatorChart';

import FullKDChart from '../pic1/FullKDChart';
import FullMACDChart from '../pic1/FullMACDChart'
import FullAvg520Chart from '../pic1/FullAvg520Chart'
import FullRSIChart from '../pic1/FullRSIChart'
import FullDMIChart from '../pic1/FullDMIChart'
import FullSARChart from '../pic1/FullSARChart'
import FullPressureChart from '../pic1/FullPressureChart';
import FullHLPChart from '../pic1/FullHLPChart';
import FullAvg6020Chart from '../pic1/FullAvg6020Chart'
import FullBollingerChart from '../pic1/FullBollingerChart';
import FullCantChart from '../pic1/FullCantChart'
import FullBrokerChart from '../pic1/FullBrokerChart'
import FullAlligatorChart from '../pic1/FullAlligatorChart'

import Avatar from '@mui/material/Avatar';
import { deepOrange, green } from '@mui/material/colors';
import CCIChart from '../pic/CCIChart'
import FullCCIChart from '../pic1/FullCCIChart';
import HighLowChart from '../pic/HighLowChart'
import FullHighLowChart from '../pic1/FullHighLowChart';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ModifyLoveDialog from '../love/ModifyLoveDialog';

import WilliamsChart from '../pic/WilliamsChart'
import FullWilliamsChart from '../pic1/FullWilliamsChart';


export default function OSCItem(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const [openKD, setOpenKD] = React.useState(false);
    const [openMACD, setOpenMACD] = React.useState(false);
    const [openAvg520, setOpenAvg520] = React.useState(false);
    const [openRSI, setOpenRSI] = React.useState(false);
    const [openDMI, setOpenDMI] = React.useState(false);
    const [openSAR, setOpenSAR] = React.useState(false);
    const [openPressure, setOpenPressure] = React.useState(false);
    const [openHLP, setOpenHLP] = React.useState(false);
    const [openAvg60, setOpenAvg60] = React.useState(false);
    const [openBolling, setOpenBolling] = React.useState(false);
    const [openCant, setOpenCant] = React.useState(false);
    const [openBroker, setOpenBroker] = React.useState(false);
    const [openCCI, setOpenCCI] = React.useState(false);
    const [openWilliams, setOpenWilliams] = React.useState(false);
    const [openAlligator, setOpenAlligator] = React.useState(false);

    const [openHighLow, setOpenHighLow] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const openMenu = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openDetail = (dtp) => {
        if (dtp === 0) {
            setOpenKD(true)
        } else if (dtp === 1) {
            setOpenMACD(true)
        } else if (dtp === 2) {
            setOpenAvg520(true)
        } else if (dtp === 3) {
            setOpenRSI(true)
        } else if (dtp === 4) {
            setOpenDMI(true)
        } else if (dtp === 5) {
            setOpenSAR(true)
        } else if (dtp === 6) {
            setOpenPressure(true)
        } else if (dtp === 7) {
            setOpenHLP(true)
        } else if (dtp === 8) {
            setOpenAvg60(true)
        } else if (dtp === 9) {
            setOpenBolling(true)
        } else if (dtp === 10) {
            setOpenCant(true)
        } else if (dtp === 11) {
            setOpenBroker(true)
        } else if (dtp === 12) {
            setOpenCCI(true)
        } else if (dtp === 13) {
            setOpenHighLow(true)
        } else if (dtp === 14) {
            setOpenWilliams(true)
        } else if (dtp === 16) {
            setOpenAlligator(true)
        } else setOpenKD(true)
    }

    const getColor = () => {
        if (props.row != null && props.row.p != null) {
            if (props.row.p.i > 0) return "red"
            else if (props.row.p.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (props.row != null && props.row.p != null) {
            if (props.row.p.i > 0) return "▲" + props.row.p.i + '元'
            else if (props.row.p.i < 0) return "▼" + props.row.p.i + '元'
            else return props.row.p.i + '元'
        } else return '-元'
    }

    const getChart = () => {
        if (props.tp === 0) {
            return <KDChart tp={getFullTp()} row={props.row} openDetail={openDetail} />
        } else if (props.tp === 1) {
            return <MACDChart row={props.row} openDetail={openDetail} />
        } else if (props.tp === 2) {
            return <AvgChart row={props.row} openDetail={openDetail} />
        } else if (props.tp === 3) {
            return <RSIChart row={props.row} openDetail={openDetail} />
        } else if (props.tp === 4) {
            return <DMIChart row={props.row} openDetail={openDetail} />
        } else if (props.tp === 5) {
            return <SARChart row={props.row} openDetail={openDetail} />
        } else if (props.tp === 6) {
            return <PressureChart row={props.row} openDetail={openDetail} />
        } else if (props.tp === 7) {
            return <HPLChart row={props.row} openDetail={openDetail} />
        } else if (props.tp === 8) {
            return <Avg520Chart row={props.row} openDetail={openDetail} />
        } else if (props.tp === 9) {
            return <Avg6020Chart row={props.row} openDetail={openDetail} />
        } else if (props.tp === 10) {
            return <BollingerChart row={props.row} openDetail={openDetail} />
        } else if (props.tp === 11) {
            return <CantChart row={props.row} openDetail={openDetail} />
        } else if (props.tp === 12) {
            return <BrokerChart row={props.row} openDetail={openDetail} menu={props.menu} />
        } else if (props.tp === 13) {
            return <CCIChart row={props.row} openDetail={openDetail} menu={props.menu} />
        } else if (props.tp === 14) {
            return <HighLowChart row={props.row} openDetail={openDetail} menu={props.menu} />
        } else if (props.tp === 15) {
            return <WilliamsChart row={props.row} openDetail={openDetail} menu={props.menu} />
        } else if (props.tp === 16) {
            return <AlligatorChart row={props.row} openDetail={openDetail} menu={props.menu} />
        } else if (props.tp === 17) {
            return <KDChart tp={getFullTp()} row={props.row} openDetail={openDetail} />
        } else if (props.tp === 18) {
            return <KDChart tp={getFullTp()} row={props.row} openDetail={openDetail} />
        } else return <MACDChart row={props.row} openDetail={openDetail} />
    }



    const clickLove = () => {
        let param = {
            sn: getId(),
            sid: props.row.mmoo.sid,
            tp: props.tp,
            froms: props.menu
        }

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }

        fetch("/osc/v1/stock2/mmoo/clicklove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        // if(props.refresh != null) props.refresh()
                        if (props.updateLove != null) props.updateLove(props.row.mmoo.sid, props.tp)
                    }
                }
            );
    }

    const handleMenuChange = (newValue) => {
        setAnchorEl(null);
        setOpenSetting(true)
    };

    const convD = (money) => {
        if(money > 1000000 || money < -1000000) return (money / 1000000).toFixed(2) + "百萬"
        else if(money > 10000 || money < -10000) return (money / 10000).toFixed(2) + "萬"
        else return money
    }

    const getFullTp = () => {
        if (props.tp === 17) return 1
        else if(props.tp == 18) return 2
        else return 0
    }

    return (
        <Stack spacing={0} sx={{ m: 1, mb: 0.5, p: 0, border: 2, borderColor: theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main, boxShadow: 0.5, borderRadius: '0px' }}>
            {open ? <Detail updateLove={props.updateLove} tp={props.tp} menu={props.menu} row={props.row} close={setOpen} open={open} sid={props.row.mmoo.sid} /> : null}
            {openKD ? <FullKDChart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenKD} open={openKD} sid={props.row.mmoo.sid} /> : null}
            {openMACD ? <FullMACDChart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenMACD} open={openMACD} sid={props.row.mmoo.sid} /> : null}
            {openAvg520 ? <FullAvg520Chart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenAvg520} open={openAvg520} sid={props.row.mmoo.sid} /> : null}
            {openRSI ? <FullRSIChart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenRSI} open={openRSI} sid={props.row.mmoo.sid} /> : null}
            {openDMI ? <FullDMIChart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenDMI} open={openDMI} sid={props.row.mmoo.sid} /> : null}
            {openSAR ? <FullSARChart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenSAR} open={openSAR} sid={props.row.mmoo.sid} /> : null}
            {openPressure ? <FullPressureChart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenPressure} open={openPressure} sid={props.row.mmoo.sid} /> : null}
            {openHLP ? <FullHLPChart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenHLP} open={openHLP} sid={props.row.mmoo.sid} /> : null}
            {openAvg60 ? <FullAvg6020Chart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenAvg60} open={openAvg60} sid={props.row.mmoo.sid} /> : null}
            {openBolling ? <FullBollingerChart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenBolling} open={openBolling} sid={props.row.mmoo.sid} /> : null}
            {openCant ? <FullCantChart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenCant} open={openCant} sid={props.row.mmoo.sid} /> : null}
            {openBroker ? <FullBrokerChart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenBroker} open={openBroker} sid={props.row.mmoo.sid} /> : null}
            {openCCI ? <FullCCIChart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenCCI} open={openCCI} sid={props.row.mmoo.sid} /> : null}
            {openWilliams ? <FullWilliamsChart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenWilliams} open={openWilliams} sid={props.row.mmoo.sid} /> : null}
            {openAlligator ? <FullAlligatorChart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenAlligator} open={openAlligator} sid={props.row.mmoo.sid} /> : null}

            {openHighLow ? <FullHighLowChart updateLove={props.updateLove} tp={props.tp} menu={props.menu} mmoo={props.row} close={setOpenHighLow} open={openHighLow} sid={props.row.mmoo.sid} /> : null}
            {openSetting ? <ModifyLoveDialog refresh={props.refresh} sid={props.row.mmoo.sid} open={openSetting} close={setOpenSetting}></ModifyLoveDialog> : null}

            <Box display={'flex'}>
                <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 1, pt: 0.5, alignSelf: 'center' }} >
                    {props.row.islv ? <Favorite color="error" onClick={e => clickLove()} /> :
                        <FavoriteBorderOutlined onClick={e => clickLove()} />}
                    {props.row.p != null && <Typography sx={{ fontSize: 30, fontWeight: 'bold', alignSelf: 'center' }} onClick={e => setOpen(true)}>{props.row.p.n.substr(0, 4)}</Typography>}
                    <Typography variant="caption" sx={{ fontWeight: 'normal' }} onClick={e => setOpen(true)}>{props.row.mmoo.sid}</Typography>
                    {/* </Stack> */}
                </Stack>
                {/* <Stack direction={"column"}> */}
                <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} onClick={e => setOpen(true)}>
                    {props.row.p != null && <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{props.row.p.p}元</Typography>}
                    <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                </Stack>

                {/* <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{(props.row.p.a / 1000).toFixed(0)}張</Typography> */}
                {/* <Typography variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'right' }} onClick={e => setOpen(true)}>{props.row.mmoo.sid}</Typography> */}
                {/* </Stack> */}
            </Box>

            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} />
            {/* <div style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
            <Box sx={{ mt: 0.5 }} onClick={e => setOpen(true)}>
                <div>
                    {props.row.p != null && <Typography variant="caption" sx={{ p: 1, alignSelf: 'center' }}>近{props.frombroker ? 120 : 240}日交易{props.row.mmoo.tran_count}次，今日{(props.row.p.a / 1000).toFixed(0)}張</Typography>}
                </div>
                <Stack spacing={2} direction={'row'} sx={{ mt: 0.5, justifyContent: 'center' }}>
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>勝率%</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.mmoo.win_rate != null ? (props.row.mmoo.win_rate).toFixed(2) : 0}</Typography>
                    </Stack>
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>期望值</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }} color={props.row.mmoo.ev >= 0 ? "error" : "inherit"} >{convD(props.row.mmoo.ev)}</Typography>
                    </Stack>
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>報酬率%</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.mmoo.roi ? (props.row.mmoo.roi).toFixed(2) : 0}</Typography>
                    </Stack>
                </Stack>
            </Box>

            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} variant="middle" />

            <div style={{display:'flex'}}>
                <Stack sx={{ m: 1, flex: 1 }} direction={'row'} spacing={0.5}>
                    {/* {getLight()} */}
                    <Button startIcon={<Lightbulb />} color={props.row.inl ? "error" : "inherit"} disableElevation size="small" variant="contained" disabled={!props.row.inl} >進場燈</Button>
                    <Button startIcon={<Lightbulb />} color={props.row.outl ? "success" : "inherit"} disableElevation size="small" variant="contained" disabled={!props.row.outl} >出場燈</Button>
                    {props.row.status != null && props.row.status > 0 && <Avatar sx={{ height: '30px', width: '30px', bgcolor: deepOrange[500] }} variant="rounded">
                        持
                    </Avatar>}
                </Stack>
                {props.islove && <IconButton sx={{}}  onClick={e => handleMenuClick(e)}>
                    <MoreVertIcon />
                </IconButton>}
                <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={e => handleMenuChange(0)}>修改</MenuItem>
                    </Menu>
            </div>
            {getChart()}
        </Stack>

    );
}
