import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import SettingsInputComponent from '@mui/icons-material/SettingsInputComponent'
import Drafts from '@mui/icons-material/Drafts'
import FilterVintage from '@mui/icons-material/FilterVintage'
import Person from '@mui/icons-material/Person'
import SelfImprovement from '@mui/icons-material/SelfImprovement'
import CalendarViewMonth from '@mui/icons-material/CalendarViewMonth'
import SportsKabaddi from '@mui/icons-material/SportsKabaddi'

import Header from './Header'

import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import OSCContainer from '../osc1/OSCContainer'
import LoveContainer from '../love/LoveContainer'
import {getId} from '../util/ID'
import AccountContainer from '../account/AccountContainer'
import OSCContainer1 from '../osc1/OSCContainer1'
import ProfileContainer from '../profile/ProfileContainer';

const useStyles = makeStyles(theme => ({
    root: {
        // color: "green",
        "&$selected": {
            color: theme.palette.mode === "light" ? "#575555" : null
        },
    },
    selected: {
        "&$selected": {
            color: theme.palette.mode === "light" ? "#575555" : null
        },
    }
}));


export default function Home(props) {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const classes = useStyles();


    const getContext = () => {
        if (value === 11) return <LoveContainer />
        else if(value === 2) return <ProfileContainer />
        else if(value === 1) return <OSCContainer1 />
        else if(value === 7) return <AccountContainer />
        else return <OSCContainer />
        // if (value === 0) return <SearchContainer2 ismobile={true} />
        // else if (value === 1) return <WindContainer ismobile={true} location={props.location.pathname} />
        // else if (value === 2) return <FriendContainerM ismobile={true} />
        // else if (value === 3) return <AccountMenu4 ismobile={true} location={props.location.pathname}/>
        // else if (props.location.pathname === "/g" && value === 4) return <ComDonationg ismobile={true} />
        // else if (props.location.pathname === "/i" && value === 4) return <ComDonationi ismobile={true} />
        // else if (value === 4) return <ComDonation ismobile={true} />
        // else if (value === 5) return <DatingContainer ismobile={true} location={props.location.pathname} />
        // else if (value === 7) return <GroupContainer ismobile={true} location={props.location.pathname} />
        // else if (value === 8) return <CalContainer ismobile={true} location={props.location.pathname} />
        // else if (value === 9) return <JobContainer ismobile={true} location={props.location.pathname} />
        // else if (value === 10) return <GDContainer ismobile={true} location={props.location.pathname} />
        // else if (value === 11) return <MeetContainer ismobile={true} location={props.location.pathname} />        
        // else return null
    }




    // function uuidv4() {
    //     return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    //         (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    //     );
    // }


    useEffect(() => {
        getId()
    }, []);

    return (
        <Box sx={{ bgcolor: 'background.default', color: 'text.primary', }} style={{ position: 'absolute', height: "100vh", overflowY: "hidden", width: '100%' }}>
            {/* <div style={{ position: 'absolute', height: "100vh", overflowY: "hidden", width: '100%' }}> */}
            <div style={{ top: 0 }} >
                <Header />
            </div>

            <div style={{ overflowY: 'hidden', width: '100%' }}>
                {getContext()}
            </div>

            <Paper sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction value={2} sx={{ minWidth: 0 }} classes={classes} label="交易燈" icon={<Drafts sx={{ color: theme.palette.mode === "light" ? "#4f4f4f" : null }} />} />
                    <BottomNavigationAction value={0} sx={{ minWidth: 0 }} classes={classes} label="進場燈" icon={<SettingsInputComponent sx={{ color: theme.palette.mode === "light" ? "#4f4f4f" : null }} />} />
                    {/* <BottomNavigationAction value={1} sx={{ minWidth: 0 }} classes={classes} label="突破燈" icon={<SelfImprovement sx={{ color: theme.palette.mode === "light" ? "#4f4f4f" : null }} />} /> */}
                    <BottomNavigationAction value={11} sx={{ minWidth: 0 }} classes={classes} label="自選" icon={<CalendarViewMonth sx={{ color: theme.palette.mode === "light" ? "#4f4f4f" : null }} />} />
                    <BottomNavigationAction value={7} sx={{ minWidth: 0 }} classes={classes} label="序號" icon={<SportsKabaddi sx={{ color: theme.palette.mode === "light" ? "#4f4f4f" : null }} />} />
                </BottomNavigation>
            </Paper>
            {/* </div> */}
        </Box>
    );
}

