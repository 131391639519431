import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart, ScatterChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';
import BuySellTable from './BuySellTable';
import StockHeader from './StockHeader';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

echarts.use(
    [ScatterChart, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


export default function FullBollingerChart(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    function genoption1() {
        let buymark = result.bs.map(v => { return { symbolRotate: -180, symbolOffset: [0, -18], coord: [v.bymd, v.bprice], itemStyle: { color: "#d41c1c" }, } })
        let sellmark = result.bs.map(v => { return { symbolOffset: [0, 18], coord: [v.symd, v.sprice], itemStyle: { color: "#65b206" }, } })
        let restmark = result.nowb.map(v => { return { symbolRotate: -180, symbolOffset: [0, -18], coord: [v.ymd, v.price], itemStyle: { color: "#d41c1c" }, } })
        let k = {
            animation: false,
            grid: [{
                top: 25,
                left: 0,
                right: 5,
                height: 250,
                containLabel: true
            }],
            legend: {
                data: ['上', '中', '下'],
                right: 0,
                icon: 'roundRect',
                formatter: function (name) {
                    let value = result.data[result.data.length - 1].v1
                    if (name === '中') {
                        value = result.data[result.data.length - 1].v2
                    } else if (name === '下') {
                        value = result.data[result.data.length - 1].v2
                    }
                    return name + " : " + value
                }
            },
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                }
            }],
            series: [
                {
                    name: '日K',
                    type: 'candlestick',
                    data: result.data.map(v => { return v.y }),
                    selectedMode: 'single',
                    markPoint: {
                        symbolSize: 18,
                        symbol: 'triangle',
                        data: buymark.concat(sellmark).concat(restmark),
                    }
                },
                {
                    name: '上',
                    type: 'line',
                    data: result.data.map(v => v.v1),
                    smooth: true,
                    showSymbol: false,
                }, {
                    name: '中',
                    type: 'line',
                    data: result.data.map(v => v.v2),
                    smooth: true,
                    showSymbol: false,
                }, {
                    name: '下',
                    type: 'line',
                    data: result.data.map(v => v.v3),
                    smooth: true,
                    showSymbol: false,
                }
            ]
        };

        let x1 = {
            type: 'category',
            boundaryGap: false,
            data: result.data.map(v => v.x)
        }

        if (fullScreen) {
            k['tooltip'] = getMobileTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getMobileDataZoom(80, 100)

            x1['axisPointer'] = {
                show: true
            }
        } else {
            k['tooltip'] = getComputerTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getComputerDataZoom(50, 100, 470)
        }

        k['xAxis'] = [x1]
        return k;
    }

    const fetchData = () => {
        let param = {
            id: props.menu,
            sid: props.mmoo.mmoo.sid
        }

        fetch("/osc/v1/stock2/mmoo/fbband", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }

    useEffect(() => {
        if (result != null) setOption(genoption1)
    }, [result]);

    useEffect(() => {
        if (props.mmoo != null) fetchData()
    }, []);


    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ p: 0 }}>
                <StockHeader updateLove={props.updateLove} menu={props.menu} sid={props.mmoo.mmoo.sid} tp={props.tp} />
            </DialogTitle>
            <DialogContent sx={{ p: 1, mt: 1, mb: 1 }}>
                {result != null && result.sg != null && <div>
                    <Typography variant='h4' sx={{ alignSelf: 'center', mt: 0.5 }}>進出策略</Typography>
                    <Stack direction="row">
                        <Typography variant="body1" sx={{ color: "#d41c1c", fontWeight: 'bold', alignSelf: 'center' }} >▼</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', whiteSpace:'nowrap' }} >進場：</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{result.sg.myrule.desc.join(" , ")}</Typography>
                    </Stack>

                    <Stack direction="row">
                        <Typography variant="body1" sx={{ color: "#65b206", fontWeight: 'bold', alignSelf: 'center' }} >▲</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', whiteSpace:'nowrap' }} >出場：</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{result.sg.outmyrule.desc.join(" , ")}</Typography>
                    </Stack>
                </div>}

                {option != null ?
                    <ReactEChartsCore
                        theme={theme.palette.mode}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 300 }}
                    />
                    : null}

                {result != null && <BuySellTable nowb={result.nowb} bs={result.bs} />}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="info" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}

