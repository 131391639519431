import React, { useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import { Divider, TextField } from '@mui/material';
import { TitleRounded } from '@mui/icons-material';
import { Alert } from '../util/ErrorMsg'
import Snackbar from '@mui/material/Snackbar';
import { Height } from '../util/ScreenHeight'
import Stack from '@mui/material/Stack';
import { getId, getLic } from '../util/ID'


function PricingContentg(props) {
    const [openError, setOpenError] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState(null)

    const [inlic, setInLic] = React.useState("");
    const [lic, setLic] = React.useState(null);
    const [lictxt, setLictxt] = React.useState("");

    const enablePrice = process.env.REACT_APP_ENABLE_PRICE

    const tiers = [
        {
            title: '免費',
            price: '0元',
            description: [
                '進場燈顯示部分查詢結果',
                '進場燈設定無法使用',
                '交易燈策略僅部分顯示',
                '可能有廣告'
            ],
            buttonText: '免費使用',
            buttonVariant: 'contained',
            tp: '0'
        },
        // {
        //     title: '早鳥價',
        //     price: '888元',
        //     description: [
        //         '優惠至2022/3/31',
        //         '進場燈均可使用',
        //         '交易燈均可使用',
        //         '無任何廣告',
        //     ],
        //     buttonText: '立即付款',
        //     buttonVariant: 'contained',
        //     tp: '33'
        // }
    ];

    const fectchAPIK = (tp) => {
        if (tp === '0') {
        } else {
            let lic = tp.toString() + '' + getLic()
            localStorage.setItem('lic', lic);

            let url = "/osc/v1/stock2/donate?sn=" + getId() + "&tp=" + tp + "&lic=" + lic + "#open"
            window.open(url, "_blank", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1024,height=800");
        }
    }

    useEffect(() => {
        checkLic();
    }, []);

    const checkLic = () => {
        let lic = localStorage.getItem('lic')

        if (lic != null) {
            let param = {
                lic: lic,
                sn: getId()
            }

            fetch("/osc/v1/stock2/checklic", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0) {
                            if (result.hlic != null) {
                                setLic(result.hlic)

                                if (result.hlic.licok === 1 || result.hlic.tp === 3) {
                                    localStorage.setItem('noad', 1);
                                } else localStorage.setItem('noad', 0);

                            } else {
                                localStorage.setItem('noad', 0);
                            }
                        } else {
                            localStorage.setItem('noad', 0);
                        }
                    },
                    error => {
                        localStorage.setItem('noad', 0);
                    }
                );
        }
    }



    const fectchAPIK2 = (tp) => {
        let url = "https://stock-candle.com/#/rules"

        if (tp === "0")
            url = "https://stock-digger.com/#/rules"
        else if (tp === "2")
            url = "https://apps.apple.com/tw/app/%E8%82%A1%E7%A5%A8%E6%8C%96%E5%9C%9F%E6%A9%9F-%E6%89%BE%E8%A2%AB%E4%BD%8E%E4%BC%B0%E7%9A%84%E5%A5%BD%E8%82%A1%E7%A5%A8/id1481434455#open"
        else if (tp === "3")
            url = "https://2single.tw/#/"

        window.open(url, "_blank", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1024,height=800");
    }

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenError(false);
    };

    const getBottom = () => {
        if (props.ismobile) {
            return 58
        } else {
            return 0
        }
    }


    const checkLicByLic = () => {
        let lic = inlic

        if (lic != null) {
            let param = {
                lic: lic,
                sn: getId()
            }

            fetch("/osc/v1/stock2/checklic", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0) {
                            if (result.hlic != null) {
                                setLic(result.hlic)
                                setLictxt("")

                                if (result.hlic.licok === 1) {
                                    localStorage.setItem('lic', lic);
                                    localStorage.setItem('noad', 1);
                                } else localStorage.setItem('noad', 0);

                            } else {
                                localStorage.setItem('noad', 0);
                                setLictxt("此序號不存在")
                            }
                        } else {
                            localStorage.setItem('noad', 0);
                            setLictxt("此序號不存在")
                        }
                    },
                    error => {
                        localStorage.setItem('noad', 0);
                        setLictxt("此序號不存在")
                    }
                );
        }
    }

    const inputLic = event => {
        setInLic(event.target.value)
    }

    const getTitle = () => {
        if (lic === null) return '免費'
        else if (lic.tp === 33) return '無使用期限'
        else if (lic.tp === 34) return '月訂制'
        else if (lic.tp === 35) return '年訂制'
        else if (lic.tp === 36) return '無使用期限'
        else return ''
    }

    const getExpireTitle = () => {
        if (lic.expire != null)
            return lic.expire
        else if (lic.tp != null && (lic.tp === 0 || lic.tp === 7 || lic.tp === 15 || lic.tp === 34 || lic.tp === 35))
            return "訂閱期間有效"
        else
            return '無特定期限'
    }

    const clearLic = () => {
        localStorage.removeItem('lic')
        localStorage.setItem('noad', 0);
        setLictxt("")
        setLic(null)
    }

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />

            <Snackbar open={openError} autoHideDuration={3000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="warning" sx={{ width: '100%' }}>
                    {errorMsg}
                </Alert>
            </Snackbar>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ height: Height.subheader, maxHeight: Height.subheader, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
            >
                {/* <Toolbar sx={{ flexWrap: 'wrap' }}> */}
                <Stack direction={"row"} sx={{ p: 1 }}>
                    <AutoAwesome style={{ width: 22, height: 22, marginRight: 5 }} />
                    <Typography color="inherit" noWrap sx={{ flexGrow: 1, fontSize: 16 }}>
                        方案列表
                    </Typography>
                </Stack>
            </AppBar>
            {/* Hero unit */}
            {/* ,position: 'absolute', top: getTop(), bottom: getBottom(), overflowY: 'auto' */}
            <Box sx={{ border: 'solid', borderColor: "#d9d9d9", borderWidth: 1, flexGrow: 1, p: 1.5, pb: 5, position: 'absolute', top: Height.header + Height.subheader, bottom: getBottom(), overflowY: 'auto', width: '100%' }}>

                {/* <Box sx={{ border: 'solid', borderColor: "#d9d9d9", borderWidth: 1, flexGrow: 1, p: 1.5, pb: 5, overflowY: 'scroll' }}> */}
                <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 4, pb: 2 }}>
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        購買序號
                    </Typography>
                </Container>
                <Container maxWidth="md" component="main">
                    <Grid container spacing={1} alignItems="flex-end">
                        {tiers.map((tier) => (
                            <Grid
                                item
                                key={tier.title}
                                xs={12}
                                sm={6}
                                md={4}
                            >
                                <Card>
                                    <CardHeader
                                        title={tier.title}
                                        subheader={tier.subheader}
                                        titleTypographyProps={{ align: 'center' }}
                                        action={tier.title === 'Pro' ? <StarIcon /> : null}
                                        subheaderTypographyProps={{
                                            align: 'center',
                                        }}
                                        sx={{
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'light'
                                                    ? theme.palette.grey[200]
                                                    : theme.palette.grey[700],
                                        }}
                                    />
                                    <CardContent>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'baseline',
                                                mb: 2,
                                            }}
                                        >
                                            <Typography component="h2" variant="h3" color="text.primary">
                                                ${tier.price}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                / 一次性
                                            </Typography>
                                        </Box>
                                        <ul>
                                            {tier.description.map((line) => (
                                                <Typography
                                                    component="li"
                                                    variant="subtitle1"
                                                    align="center"
                                                    key={line}
                                                >
                                                    {line}
                                                </Typography>
                                            ))}
                                        </ul>
                                    </CardContent>
                                    <CardActions>
                                        <Button disabled={tier.title === '正式價' ? true : false} color="error" fullWidth variant={tier.buttonVariant} onClick={e => fectchAPIK(tier.tp)}>
                                            {tier.buttonText}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}

                        <Grid
                            item
                            key={"198"}
                            xs={12}
                            sm={6}
                            md={4}
                        >
                            <Card>
                                <CardHeader
                                    title={"月訂制"}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                        }}
                                    >
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            $198
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            / 每月
                                        </Typography>
                                    </Box>
                                    <ul>
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="center"
                                            key={'0'}
                                        >
                                            每月自動續扣
                                        </Typography>
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="center"
                                            key={'1'}
                                        >
                                            進場燈均可使用
                                        </Typography>
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="center"
                                            key={'2'}
                                        >
                                            交易燈均可使用
                                        </Typography>
                                        <Typography
                                            component="li"
                                            variant="caption"
                                            align="center"
                                            key={'3'}
                                            color="red"
                                        >
                                            取消訂閱: 將APP名稱,大名與手機末五碼寄信到jookershop@gmail.com隨即會有專人處理
                                        </Typography>

                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button color="error" fullWidth variant='contained' onClick={e => fectchAPIK('34')}>
                                        立即付款
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid
                            item
                            key={"1680"}
                            xs={12}
                            sm={6}
                            md={4}
                        >
                            <Card>
                                <CardHeader
                                    title={"無使用期限"}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                        }}
                                    >
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            $1688
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            / 一次
                                        </Typography>
                                    </Box>
                                    <ul>
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="center"
                                            key={'0'}
                                        >
                                            一次性付款
                                        </Typography>
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="center"
                                            key={'1'}
                                        >
                                            進場燈均可使用
                                        </Typography>
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="center"
                                            key={'2'}
                                        >
                                            交易燈均可使用
                                        </Typography>
                                        {/* <Typography
                                            component="li"
                                            variant="caption"
                                            align="center"
                                            key={'3'}
                                            color="red"
                                        >
                                            取消訂閱: 將APP名稱,大名與手機末五碼寄信到jookershop@gmail.com隨即會有專人處理
                                        </Typography> */}

                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button color="error" fullWidth variant='contained' onClick={e => fectchAPIK('36')}>
                                        立即付款
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>


                    </Grid>
                </Container>

                <Divider sx={{ mt: 8, mb: 8 }} />

                {lic != null && (lic.licok === 1) ?
                    <Container maxWidth="md" component="main" style={{ marginBottom: 30 }}>
                        <Grid container spacing={5} alignItems="flex-end">
                            <Grid item key={"aa"} xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader
                                        title={getTitle()}
                                        subheader={<div>說明書 <Link onClick={(e) => window.open('stock-candle.pdf')}>下載</Link></div>}
                                        titleTypographyProps={{ align: 'center' }}
                                        subheaderTypographyProps={{ align: 'center' }}
                                        action={<StarIcon />}
                                    />
                                    <CardContent>
                                        <div >
                                            <Typography align="center" variant="h6" color="textPrimary">
                                                序號
                                            </Typography>
                                        </div>
                                        <div >
                                            <Typography align="center" component="h2" variant="h3" color="textPrimary">
                                                {lic.lic}
                                            </Typography>
                                            <Typography variant="h6" color="textSecondary">

                                            </Typography>
                                        </div>

                                        <ul>
                                            <Typography component="li" variant="subtitle1" align="center" key={Math.random()}>
                                                使用期限：{getExpireTitle()}
                                            </Typography>
                                            <Typography component="li" variant="caption" align="center" color="textSecondary">
                                                妥善保管，勿公開給他人使用
                                            </Typography>
                                            {lic.tp != 33 && <Typography component="li" variant="caption" align="center" color="textSecondary">
                                                取消訂閱: 將APP名稱,大名與手機末五碼寄信到jookershop@gmail.com隨即會有專人處理
                                            </Typography>}


                                        </ul>
                                    </CardContent>
                                    <CardActions>
                                        <Button style={{ width: '100%', backgroundColor: "#1c1c1c", color: "#FFFFFF" }} size="large" fullWidth variant="outlined" align="center" color="primary" onClick={event => clearLic()}>清除
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container> : <Container maxWidth="md" component="main" style={{ marginBottom: 30 }}>
                        <Grid container spacing={5} alignItems="flex-end">
                            <Grid item key={"aa"} xs={12} sm={12} md={12}>
                                <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 0, pb: 0 }}>
                                    <Typography
                                        component="h1"
                                        variant="h2"
                                        align="center"
                                        color="text.primary"
                                        gutterBottom
                                    >
                                        輸入序號
                                    </Typography>
                                </Container>


                                <Card>
                                    {/* <CardHeader
                                        title={'輸入序號'}
                                        subheader={""}
                                        titleTypographyProps={{ align: 'center' }}
                                        subheaderTypographyProps={{ align: 'center' }}

                                    /> */}
                                    <CardContent>
                                        <TextField
                                            id="standard-name"
                                            label="輸入序號"
                                            onChange={e => inputLic(e)}
                                            fullWidth
                                            style={{ fontSize: 18 }}
                                            helperText={lictxt}
                                        />
                                        <br />
                                        <Typography variant="caption" color="textSecondary" component="p">
                                            <li>收到付款完成通知信，商店訂單編號前面8碼即是序號</li>
                                            <li>相同序號皆可共用於手機，平板，電腦等平台</li>
                                            <li>如何取得說明書? <Link onClick={(e) => window.open('stock-candle.pdf')}>下載</Link></li>
                                            {/* <li>如何取得序號? <Link onClick={(e) => handleOk1()}>請洽官網方案列表</Link></li> */}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button style={{ width: '100%', backgroundColor: "#1c1c1c", color: "#FFFFFF" }} size="large" fullWidth variant="outlined" align="center" color="primary" onClick={event => checkLicByLic()}>送出
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>}



                <Divider sx={{ mt: 8, mb: 8 }} />

                <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 0, pb: 6 }}>
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        其它軟體
                    </Typography>
                    <Typography variant="body2" align="center" color="text.secondary" component="p">
                        開發者同樣也開發了其它優質的軟體，歡迎多多下載支持。
                    </Typography>
                </Container>
                {/* End hero unit */}
                <Container maxWidth="md" component="main" sx={{ mb: 8 }}>
                    <Grid container spacing={3} alignItems="flex-end">
                        <Grid
                            item
                            key={'1'}
                            xs={12}
                            sm={6}
                            md={6}
                        >
                            <Card>
                                <CardHeader

                                    title={'股票挖土機'}
                                    // subheader={'最完整的選股軟體'}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                        }}
                                    >
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            免費
                                        </Typography>
                                        <Typography variant="caption" color="text.primary">
                                            (部分功能需訂閱)
                                        </Typography>
                                    </Box>

                                    <ul>
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="center"
                                            key={"line"}
                                        >
                                            {'最優質的選股軟體。將自己的投資理念轉換成幾條簡單的文字規則，讓您從近2000支的股票與ETF中，快速的挖出珍貴的投資標的。'}
                                        </Typography>
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    {/* <Button color="error" fullWidth variant={'contained'} onClick={e => fectchAPIK2('1')}>
                                        Android
                                    </Button>
                                    <Button color="success" fullWidth variant={'contained'} onClick={e => fectchAPIK2('2')}>
                                        iOS
                                    </Button> */}
                                    <Button color="info" fullWidth variant={'contained'} onClick={e => fectchAPIK2("0")}>
                                        使用
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            key={'2'}
                            xs={12}
                            sm={6}
                            md={6}
                        >
                            <Card>
                                <CardHeader

                                    title={'單單'}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                        }}
                                    >
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            免費
                                        </Typography>
                                    </Box>

                                    <ul>
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="center"
                                            key={"line"}
                                        >
                                            {'最優質的交友軟體，認識新朋友的最佳管道。利用超過20種以上關鍵問題，快速的找到相同觀念的朋友讓聊天更有話題。'}
                                        </Typography>
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    {/* <Button color="error" fullWidth variant={'contained'} onClick={e => fectchAPIK2('1')}>
                                        Android
                                    </Button>
                                    <Button color="success" fullWidth variant={'contained'} onClick={e => fectchAPIK2('2')}>
                                        iOS
                                    </Button> */}
                                    <Button color="info" fullWidth variant={'contained'} onClick={e => fectchAPIK2("3")}>
                                        使用
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>

            </Box>


        </div>
    );
}

export default function AccountContainer() {
    return <PricingContentg />;
}