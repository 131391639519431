import React, { useEffect, useRef } from 'react';
import { Height } from '../util/ScreenHeight'
import BrokerSelector1 from './BrokerSelector1'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { getId } from '../util/ID'

import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import OSCItem from '../osc1/OSCItem'

export default function BrokerContainer(props) {
    const [page, setPage] = React.useState(1);
    // const [sort, setSort] = React.useState(0);
    // const [count, setCount] = React.useState(50);
    const [total, setTotal] = React.useState(100)
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [tp, setTp] = React.useState(0)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const fetchData = () => {
        setLoading(true)

        let sort = localStorage.getItem('bsearch_sort') != null ? localStorage.getItem('bsearch_sort') : '0'
        let light = localStorage.getItem('bsearch_light') != null ? localStorage.getItem('bsearch_light') : '0'
        let count = localStorage.getItem('bsearch_count') != null ? localStorage.getItem('bsearch_count') : '0'

        let param = {
            page: page,
            sort: sort,
            light: light,
            c: count,
            id: menu,
            sn: getId()
        }

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }

        fetch("/osc/v1/stock2/mmoo", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null && result.status === 0) {
                        if (result.data != null && result.data.length > 0) {
                            setTotal(result.total)
                            setResult(result.data)
                            setTp(result.tp)
                        } else {
                            setTotal(0)
                            setResult([])
                        }
                    }
                }
            );
    }

    useEffect(() => {
        if (menu != null) fetchData()
    }, [menu, page]);


    const updateLove = (sid) => {
        if (sid != null && result != null) {
            let nresult = result.map(r => {
                if (r.mmoo.sid === sid) {
                    r['islv'] = !r.islv
                    return r
                } else return r
            })
            setResult(nresult)
        }
    }


    return (
        <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
            <BrokerSelector1 setMenu={setMenu} refresh={fetchData} />

            {loading ? <Box sx={{ position: 'absolute', top: Height.header + Height.subheader - 2, width: '100%' }}><LinearProgress color="info" /></Box> : <Grid container spacing={0} sx={{ p: 0.5, width: '100%', bgcolor: 'background.paper', position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 100 : 55, overflowY: 'scroll' }}>
                {result != null && result.length === 0 &&
                    <Typography variant="body1" sx={{ width: '100%', textAlign: 'center', alignSelf: 'center', m: 1 }}>很抱歉，無法找到符合條件的股票。</Typography>
                }
                {result != null && result.map(row => {
                    return <Grid key={row.mmoo.sid} item xs={12} sm={6} md={4} lg={3}>
                        <OSCItem updateLove={updateLove} tp={tp} key={row.mmoo.sid} row={row} menu={menu} frombroker={true} />
                    </Grid>
                })}
            </Grid>}



            {<Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen?60:0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
            </Box>}
        </Box>
    );
}
