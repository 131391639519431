import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import SearchCompany from './SearchCompany';
import { getId } from '../util/ID'


export default function ModifyLoveDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [tp, setTP] = React.useState(0);
    const [sid, setSID] = React.useState(null);
    const [tags, setTags] = React.useState([])
    const [brokers, setBrokers] = React.useState([])
    const [id, setID] = React.useState("");
    const [bid, setBID] = React.useState("");

    useEffect(() => {
        if (tags != null && tags.length > 0) setID("")
    }, [tags]);

    useEffect(() => {
        if (brokers != null && brokers.length > 0) setBID("")
    }, [brokers]);

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        setOpen(false);
        clickLove()
        if (props.close != null) props.close(false)
    };


    const clickLove = () => {
        let oo = null
        if (id != null && id != "") {
            oo = tags.find(r => r.id === id)
        } else if (bid != null && bid != "") {
            oo = brokers.find(r => r.id === bid)
        }

        let froms = ""
        if (id != "") froms = id
        else if (bid != "") froms = bid

        if (sid != null && froms != "" && oo != null) {
            let param = {
                sn: getId(),
                sid: sid,
                tp: oo.tp,
                froms: froms
            }

            if (localStorage.getItem("lic") != null) {
                param['lic'] = localStorage.getItem("lic")
            }

            fetch("/osc/v1/stock2/mmoo/modifylove", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null && result.status === 0) {
                            if (props.refresh != null) props.refresh()
                        }
                    }
                );
        }
    }

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    useEffect(() => {
        if (props.sid != null) setSID(props.sid)
    }, [props.sid]);

    const fectchTags = () => {
        let u = "/osc/v1/stock2/mmoo/menu"
        if (props.osc === "2") u = "/osc/v1/stock2/mmoo/menu2"

        fetch(u, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setTags(result)
                        // if(result.length > 0) setID(result[0].id)
                    }
                }
            );
    }


    const fectchBrokerTags = () => {
        let u = "/osc/v1/stock2/mmoo/brokermenu"

        fetch(u, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setBrokers(result)
                    }
                }
            );
    }

    useEffect(() => {
        fectchTags()
        fectchBrokerTags()
    }, []);

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"修改"}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={3} direction="column" sx={{ p: 2 }}>
                        <FormControl fullWidth >
                            <InputLabel id="demo-simple-select-label">主要策略</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={id}
                                label="主要策略"
                                onChange={e => setID(e.target.value)}
                            >
                                <MenuItem key={"none"} value="">無</MenuItem>
                                {tags != null && tags.map(t => {
                                    return <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth >
                            <InputLabel id="demo-simple-select-label1">券商分點</InputLabel>
                            <Select
                                labelId="demo-simple-select-label1"
                                id="demo-simple-select1"
                                value={bid}
                                label="券商分點"
                                onChange={e => setBID(e.target.value)}
                            >
                                <MenuItem key={"none"} value="">無</MenuItem>
                                {brokers != null && brokers.map(t => {
                                    return <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
