import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

export default function BuySellTable(props) {

    const cal = (buy, sell) => {
        let fee = localStorage.getItem('fee') != null ? localStorage.getItem('fee') : 0.1425

        let buyCost = buy * 1000 * (1 + fee / 100)
        let sellEarn = sell * 1000 * (1 - fee / 100 - 0.003)

        if (buyCost != 0)
            return (((sellEarn - buyCost) * 100) / buyCost).toFixed(2) + "%"
        else return "0%"
    }

    const getColor = (buy, sell) => {
        let fee = localStorage.getItem('fee') != null ? localStorage.getItem('fee') : 0.1425

        let buyCost = buy * 1000 * (1 + fee / 100)
        let sellEarn = sell * 1000 * (1 - fee / 100 - 0.003)

        if (sellEarn > buyCost) return "error"
        else if (buyCost > sellEarn) return "success"
        else return "inherit"
    }

    

    return (
        <div>
            <Typography variant='h4' sx={{ alignSelf: 'center',mt:0.5 }}>進出明細</Typography>
            <Typography variant='body2' sx={{ alignSelf: 'center',mt:0.5 }}>賺賠報酬率含手續費,交易稅</Typography>
            <TableContainer component={Paper}>
                <Table size="small" sx={{ minWidth: 550 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>賺賠</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>買進日期</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>買進價格</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>賣出日期</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>賣出價格</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.nowb != null && props.nowb.map((row) => {
                            return <TableRow
                                key={"a" + row.price}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="center" >
                                    <Button disableElevation variant='contained' color="inherit" sx={{ minWidth: 120 }}>未出場</Button>
                                </TableCell>

                                <TableCell align="center">
                                    {row.ymd}
                                </TableCell>
                                <TableCell align="center">{row.price}</TableCell>
                                <TableCell align="center">-</TableCell>
                                <TableCell align="center">-</TableCell>
                            </TableRow>
                        })}

                        {props.bs != null && props.bs.map((row) => {
                            return <TableRow
                                key={row.bymd}
                                sx={{ }}
                            >
                                <TableCell align="center" >
                                    <Button disableElevation variant='contained' color={getColor(row.bprice, row.sprice)} sx={{ minWidth: 120 }}> {cal(row.bprice, row.sprice)}</Button>
                                </TableCell>

                                <TableCell align="center">
                                    {row.bymd}
                                </TableCell>
                                <TableCell align="center">{row.bprice}</TableCell>
                                <TableCell align="center">{row.symd}</TableCell>
                                <TableCell align="center">{row.sprice}</TableCell>
                            </TableRow>
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
