import React, { useEffect, useRef } from 'react';
import OSCItem from './OSCItem'
import {Height} from '../util/ScreenHeight'
import OSCSelector from './OSCSelector'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import {getId } from '../util/ID'

export default function OSCContainer1() {
    const [page, setPage] = React.useState(1);
    // const [sort, setSort] = React.useState(0);
    // const [count, setCount] = React.useState(50);
    const [total, setTotal] = React.useState(100)

    const [showEmpty, setShowEmpty] = React.useState(false);
    const [emptyMsg, setEmptyMsg] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [tp, setTp] = React.useState(0)

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const fetchData = () => {
        setLoading(true)

        let sort = localStorage.getItem('search_sort') != null? localStorage.getItem('search_sort'): '0'
        let light = localStorage.getItem('search_light') != null? localStorage.getItem('search_light'): '0'
        let count = localStorage.getItem('search_count') != null? localStorage.getItem('search_count'): '0'
        let rules = localStorage.getItem('search_rules') != null? localStorage.getItem('search_rules'): []


        let param = {
            page: page,
            sort: sort,
            light: light,
            c: count,
            id: menu,
            sn: getId(),
            rules: rules
        }

        fetch("/osc/v1/stock2/mmoo", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null && result.status === 0) {
                        if (result.data != null && result.data.length > 0) {
                            setTotal(result.total)
                            setResult(result.data)
                            setTp(result.tp)
                        } else {
                            setTotal(0)
                            setResult(null)
                            setShowEmpty(true)
                            setEmptyMsg("目前尚未找到符合資料。")
                        }
                    }
                }
            );
    }

    useEffect(() => {
        if (menu != null) fetchData()
    }, [menu, page]);


    const updateLove = (sid) => {
        if(sid != null && result != null) {
            let nresult = result.map ( r => { 
                if(r.mmoo.sid === sid) {
                    r['islv'] = !r.islv
                    return r
                } else return r
            })
            setResult(nresult)
        }
    }

    return (
        <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
            <OSCSelector setMenu={setMenu} refresh={fetchData} osc={"2"}/>
            
            {loading ?<Box sx={{position:'absolute',  top: Height.header + Height.subheader-2, width: '100%' }}><LinearProgress color="info" /></Box>:<Grid container spacing={0} sx={{ p: 0.5, width: '100%', bgcolor: 'background.paper', position:'absolute', top: Height.header + Height.subheader, bottom:100, overflowY: 'scroll' }}>
                {result != null && result.map(row => {
                    return <Grid key={row.mmoo.sid} item xs={12} sm={6} md={4} lg={3}>
                        <OSCItem updateLove={updateLove} tp={tp} key={row.mmoo.sid} row={row} menu={menu} />
                    </Grid>
                })}
            </Grid>}

            {<Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: 60, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
            </Box>}
        </Box>
    );
}
