import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ColorModeContext from '../util/ColorMode'
import {Height} from '../util/ScreenHeight'
import Stack from '@mui/material/Stack';


export default function Header(props) {
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);
    // theme.palette.primary.main
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar color="transparent" position="static" elevation={0} sx={{  height: Height.header, maxHeight: Height.header }}>
                <Toolbar style={{ }}>
                    {/* <Box sx={{ flexGrow: 1 }}> */}
                        <Stack direction={"row"} sx={{ flexGrow: 1 }}>
                            <img src="lightbulb.png" style={{ width: 42, height: 42 }} />
                            <Typography variant="h5" sx={{alignSelf:'center',ml: 1,  fontWeight: 'bold' }}> 股票光明燈</Typography>
                        </Stack>
                    {/* </Box> */}
                    <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon  sx={{ color: "#000000" }}/>}
                        {/* <Brightness4Icon /> */}
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
