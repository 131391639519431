import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AllStrageRules from '../strage/AllStrageRules'

export default function OSCSetting(props) {
    const [open, setOpen] = React.useState(false);
    const [sort, setSort] = React.useState(localStorage.getItem('search_sort') != null ? localStorage.getItem('search_sort') : '0');
    const [light, setLight] = React.useState(localStorage.getItem('search_light') != null ? localStorage.getItem('search_light') : '0');
    const [count, setCount] = React.useState(localStorage.getItem('search_count') != null ? localStorage.getItem('search_count') : '0');
    const [nowRows, setNowRows] = React.useState(localStorage.getItem('search_rules') != null ? JSON.parse(localStorage.getItem('search_rules')) : [])

    const clearRules = () => {
        setNowRows([]);
    };


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        localStorage.setItem("search_sort", sort)
        localStorage.setItem("search_light", light)
        localStorage.setItem("search_count", count)
        localStorage.setItem("search_rules", JSON.stringify(nowRows))

        setOpen(false);
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    const updateRule = (id, data, enabled) => {
        if (enabled) {
            const rules = nowRows.filter(item => item.id !== id);
            const finalRules = rules.concat(data)
            setNowRows(finalRules)
        } else {
            const rules = nowRows.filter(item => item.id !== id)
            setNowRows(rules)
        }
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"搜尋設定"}
                    <Typography color="red" variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', ml: 1 }}>VIP專屬</Typography>
                </DialogTitle>
                <DialogContent>

                    <Stack spacing={3} direction="column" sx={{ p: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">排序方式</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={sort}
                                label="排序方式"
                                onChange={e => setSort(e.target.value)}
                            >
                                <MenuItem value={'0'}>{'勝率高至低'}</MenuItem>
                                <MenuItem value={'1'}>{'期望值大到小'}</MenuItem>
                                <MenuItem value={'2'}>{'報酬率高至低'}</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">顯示燈號</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={light}
                                label="顯示燈號"
                                onChange={e => setLight(e.target.value)}
                            >
                                <MenuItem value={'0'}>{'全部顯示'}</MenuItem>
                                <MenuItem value={'1'}>{'進場燈號'}</MenuItem>
                                <MenuItem value={'2'}>{'出場燈號'}</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">交易次數</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={count}
                                label="交易次數"
                                onChange={e => setCount(e.target.value)}
                            >
                                <MenuItem value={'0'}>{'0次以上'}</MenuItem>
                                <MenuItem value={'1'}>{'1次以上'}</MenuItem>
                                <MenuItem value={'2'}>{'2次以上'}</MenuItem>
                                <MenuItem value={'3'}>{'3次以上'}</MenuItem>
                                <MenuItem value={'4'}>{'4次以上'}</MenuItem>
                                <MenuItem value={'5'}>{'5次以上'}</MenuItem>
                                <MenuItem value={'6'}>{'6次以上'}</MenuItem>
                                <MenuItem value={'7'}>{'7次以上'}</MenuItem>
                                <MenuItem value={'8'}>{'8次以上'}</MenuItem>
                                <MenuItem value={'9'}>{'9次以上'}</MenuItem>
                                <MenuItem value={'10'}>{'10次以上'}</MenuItem>
                            </Select>
                        </FormControl>

                    </Stack>

                    <Divider sx={{ m: 2 }}>
                        <Chip label="進階設定" />
                    </Divider>
                    <AllStrageRules updateRule={updateRule} rows={nowRows} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={clearRules}>清除規則</Button>
                    <Button onClick={handleClose}>取消</Button>
                    <Button disabled={localStorage.getItem("noad") != "1"} onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
