import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import KDChart from '../pic/KDChart'
import Button from '@mui/material/Button';
import { Lightbulb } from '@mui/icons-material';
import MACDChart from '../pic/MACDChart'
import AvgChart from '../pic/AvgChart'
import RSIChart from '../pic/RSIChart'
import DMIChart from '../pic/DMIChart'
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import Favorite from '@mui/icons-material/Favorite'
import { getId } from '../util/ID'
import SARChart from '../pic/SARChart';
import PressureChart from '../pic/PressureChart'
import HPLChart from '../pic/HLPChart';
import Avg520Chart from '../pic/Avg520Chart';
import Avg6020Chart from '../pic/Avg6020Chart'
import BollingerChart from '../pic/BollingerChart';
import CantChart from '../pic/CantChart';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AlligatorChart from '../pic/AlligatorChart';


import FullKDChart from '../pic1/FullKDChart';
import FullMACDChart from '../pic1/FullMACDChart'
import FullAvg520Chart from '../pic1/FullAvg520Chart'
import FullRSIChart from '../pic1/FullRSIChart'
import FullDMIChart from '../pic1/FullDMIChart'
import FullSARChart from '../pic1/FullSARChart'
import FullPressureChart from '../pic1/FullPressureChart';
import FullHLPChart from '../pic1/FullHLPChart';
import FullAvg6020Chart from '../pic1/FullAvg6020Chart'
import FullBollingerChart from '../pic1/FullBollingerChart';
import FullCantChart from '../pic1/FullCantChart'
import BrokerChart from '../pic/BrokerChart';
import FullBrokerChart from '../pic1/FullBrokerChart'

import CCIChart from '../pic/CCIChart'
import FullCCIChart from '../pic1/FullCCIChart';

import WilliamsChart from '../pic/WilliamsChart'
import FullWilliamsChart from '../pic1/FullWilliamsChart';

import Avatar from '@mui/material/Avatar';
import { deepOrange, green } from '@mui/material/colors';
import HighLowChart from '../pic/HighLowChart'
import FullHighLowChart from '../pic1/FullHighLowChart';
import FullAlligatorChart from '../pic1/FullAlligatorChart';



export default function Detail(props) {
    const theme = useTheme();
    const [result, setResult] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const [openKD, setOpenKD] = React.useState(false);
    const [openMACD, setOpenMACD] = React.useState(false);
    const [openAvg520, setOpenAvg520] = React.useState(false);
    const [openRSI, setOpenRSI] = React.useState(false);
    const [openDMI, setOpenDMI] = React.useState(false);
    const [openSAR, setOpenSAR] = React.useState(false);
    const [openPressure, setOpenPressure] = React.useState(false);
    const [openHLP, setOpenHLP] = React.useState(false);
    const [openAvg60, setOpenAvg60] = React.useState(false);
    const [openBolling, setOpenBolling] = React.useState(false);
    const [openCant, setOpenCant] = React.useState(false);
    const [openBroker, setOpenBroker] = React.useState(false);
    const [openCCI, setOpenCCI] = React.useState(false);
    const [openWilliams, setOpenWilliams] = React.useState(false);
    const [openHighLow, setOpenHighLow] = React.useState(false);
    const [openAlligator, setOpenAlligator] = React.useState(false);

    const [menu, setMenu] = React.useState(null);

    const openDetail = (dtp, menu) => {
        if (menu != null) setMenu(menu)

        if (dtp === 0) {
            setOpenKD(true)
        } else if (dtp === 1) {
            setOpenMACD(true)
        } else if (dtp === 2) {
            setOpenAvg520(true)
        } else if (dtp === 3) {
            setOpenRSI(true)
        } else if (dtp === 4) {
            setOpenDMI(true)
        } else if (dtp === 5) {
            setOpenSAR(true)
        } else if (dtp === 6) {
            setOpenPressure(true)
        } else if (dtp === 7) {
            setOpenHLP(true)
        } else if (dtp === 8) {
            setOpenAvg60(true)
        } else if (dtp === 9) {
            setOpenBolling(true)
        } else if (dtp === 10) {
            setOpenCant(true)
        } else if (dtp === 11) {
            setOpenBroker(true)
        } else if (dtp === 12) {
            setOpenCCI(true)
        } else if (dtp === 13) {
            setOpenHighLow(true)
        } else if (dtp === 14) {
            setOpenWilliams(true)
        } else setOpenKD(true)
    }

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    const getColor = () => {
        if (props.row != null && props.row.p != null) {
            if (props.row.p.i > 0) return "red"
            else if (props.row.p.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (props.row != null && props.row.p != null) {
            if (props.row.p.i > 0) return "▲" + props.row.p.i + '元'
            else if (props.row.p.i < 0) return "▼" + props.row.p.i + '元'
            else return props.row.p.i + '元'
        } else return props.row.p.i + '元'
    }

    const getChart = (tp, menu) => {
        if (tp === 0) {
            return <KDChart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 1) {
            return <MACDChart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 2) {
            return <AvgChart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 3) {
            return <RSIChart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 4) {
            return <DMIChart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 5) {
            return <SARChart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 6) {
            return <PressureChart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 7) {
            return <HPLChart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 8) {
            return <Avg520Chart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 9) {
            return <Avg6020Chart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 10) {
            return <BollingerChart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 11) {
            return <CantChart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 12) {
            return <BrokerChart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 13) {
            return <CCIChart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 14) {
            return <HighLowChart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 15) {
            return <WilliamsChart row={props.row} openDetail={openDetail} menu={menu} />
        } else if (tp === 16) {
            return <AlligatorChart row={props.row} openDetail={openDetail} menu={menu} />
        }  
        
        else return <MACDChart row={props.row} openDetail={openDetail} menu={menu} />
    }

    const clickLove = () => {
        let param = {
            sn: getId(),
            sid: props.row.mmoo.sid,
            tp: props.tp,
            froms: props.menu
        }

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }

        fetch("/osc/v1/stock2/mmoo/clicklove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        if (props.refresh != null) props.refresh()
                        if (props.updateLove != null) props.updateLove(props.row.mmoo.sid, props.tp)
                    }
                }
            );
    }

    const getDetail = () => {
        let param = {
            sn: getId(),
            sid: props.sid,
        }

        fetch("/osc/v1/stock2/mmoo/detail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        setResult(result.data)
                    }
                }
            );
    }

    useEffect(() => {
        if (props.sid != null) getDetail()
    }, []);

    // useEffect(() => {
    //     if (props.sid != null) getDetail()
    // }, [props.sid]);

    const convD = (money) => {
        if(money > 1000000 || money < -1000000) return (money / 1000000).toFixed(2) + "百萬"
        else if(money > 10000 || money < -10000) return (money / 10000).toFixed(2) + "萬"
        else return money
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}

        >

            {openKD ? <FullKDChart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenKD} open={openKD} sid={props.sid} /> : null}
            {openMACD ? <FullMACDChart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenMACD} open={openMACD} sid={props.sid} /> : null}
            {openAvg520 ? <FullAvg520Chart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenAvg520} open={openAvg520} sid={props.sid} /> : null}
            {openRSI ? <FullRSIChart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenRSI} open={openRSI} sid={props.sid} /> : null}
            {openDMI ? <FullDMIChart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenDMI} open={openDMI} sid={props.sid} /> : null}
            {openSAR ? <FullSARChart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenSAR} open={openSAR} sid={props.sid} /> : null}
            {openPressure ? <FullPressureChart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenPressure} open={openPressure} sid={props.sid} /> : null}
            {openHLP ? <FullHLPChart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenHLP} open={openHLP} sid={props.sid} /> : null}
            {openAvg60 ? <FullAvg6020Chart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenAvg60} open={openAvg60} sid={props.sid} /> : null}
            {openBolling ? <FullBollingerChart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenBolling} open={openBolling} sid={props.sid} /> : null}
            {openCant ? <FullCantChart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenCant} open={openCant} sid={props.sid} /> : null}
            {openBroker ? <FullBrokerChart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenBroker} open={openBroker} sid={props.sid} /> : null}
            {openCCI ? <FullCCIChart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenCCI} open={openCCI} sid={props.sid} /> : null}
            {openWilliams ? <FullWilliamsChart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenWilliams} open={openWilliams} sid={props.sid} /> : null}
            {openHighLow ? <FullHighLowChart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenHighLow} open={openHighLow} sid={props.sid} /> : null}            
            {openAlligator ? <FullAlligatorChart updateLove={props.updateLove} tp={props.tp} menu={menu} mmoo={props.row} close={setOpenAlligator} open={openAlligator} sid={props.sid} /> : null}            


            <DialogTitle sx={{ p: 0 }}>
                <Box display={'flex'}>
                    <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 1, pt: 0.5, alignSelf: 'center' }} >
                        {/* <Avatar variant="circle" sx={{ height: 20, width: 20, alignSelf: 'center' }} src={"https://icotar.com/avatar/" + props.row.id} /> */}
                        {props.row.islv ? <Favorite color="error" onClick={e => clickLove()} /> :
                            <FavoriteBorderOutlined onClick={e => clickLove()} />}

                        <Typography sx={{ fontSize: 30, fontWeight: 'bold', alignSelf: 'center' }}>{props.row.p.n}</Typography>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{props.row.mmoo.sid}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={0.5} sx={{ p: 1, alignSelf: 'center' }}>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{props.row.p.p}元</Typography>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                    </Stack>
                </Box>
                <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                    style={{
                        border: "none",
                        height: 1,
                        margin: 0,
                    }}
                />
            </DialogTitle>
            <DialogContent sx={{ p: 1, mt: 1, mb: 1 }}>
                <Grid container spacing={0} sx={{ p: 0, width: '100%', bgcolor: 'background.paper' }}>

                    {result != null && result.map(row => {
                        return <Grid key={row.s.id} item xs={12} sm={6} md={6} lg={4} sx={{ m: 0, border: 0.5, borderColor: theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main, boxShadow: 0.5, borderRadius: '0px' }}>
                            <Box sx={{ p: 1 }}>
                                <Stack direction={'row'} spacing={0.5}>
                                    <Typography sx={{ alignSelf: 'center', fontSize: 20, fontWeight: 'bold', width: '100%' }}>{row.s.name}</Typography>
                                    {row.m != null && <Typography variant="caption" sx={{ pl: 1, alignSelf: 'center', whiteSpace:'nowrap' }}>近240日交易{row.m.tran_count}次</Typography>}
                                </Stack>

                                <Stack direction={'row'} spacing={0.5}>
                                    <Button sx={{ pl: 3, pr: 3, whiteSpace: "nowrap" }} startIcon={<Lightbulb />} color={row.inl ? "error" : "inherit"} disableElevation size="small" variant="contained" disabled={!row.inl} >進場燈</Button>
                                    <Button sx={{ pl: 3, pr: 3, whiteSpace: "nowrap" }} startIcon={<Lightbulb />} color={row.outl ? "success" : "inherit"} disableElevation size="small" variant="contained" disabled={!row.outl} >出場燈</Button>
                                    {row.status != null && row.status > 0 && <Avatar sx={{ height: '30px', width: '30px', bgcolor: deepOrange[500] }} variant="rounded">
                                        持
                                    </Avatar>}
                                </Stack>                                
                                
                                {row.m != null && <Stack spacing={2} direction={'row'} sx={{ mt: 0.5, justifyContent: 'center' }}>
                                    <Stack sx={{ justifyContent: 'center' }}>
                                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>勝率%</Typography>
                                        <Typography color={row.m.win_rate > 50 ? "red" : null} sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{row.m.win_rate}</Typography>
                                    </Stack>
                                    <Stack sx={{ justifyContent: 'center' }}>
                                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>期望值</Typography>
                                        <Typography color={row.m.ev < 0 ? "green" : null} sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{convD(row.m.ev)}</Typography>
                                    </Stack>
                                    <Stack sx={{ justifyContent: 'center' }}>
                                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>報酬率%</Typography>
                                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{row.m.roi}</Typography>
                                    </Stack>
                                </Stack>}
                                <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                                    style={{
                                        border: "none",
                                        height: 0.5,
                                        margin: 0,
                                    }} />

                                {getChart(row.s.tp, row.s.id)}
                            </Box>
                        </Grid>
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="info" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}
