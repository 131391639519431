import React, { useEffect, useRef } from 'react';
import { Height } from '../util/ScreenHeight'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { Divider } from '@mui/material';
import { getId } from '../util/ID'
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Adsense } from '@ctrl/react-adsense';

export default function CLanding(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(100)

    const [showEmpty, setShowEmpty] = React.useState(false);
    const [emptyMsg, setEmptyMsg] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const fetchData = () => {
    }

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <Box style={{ flexDirection: 'column', position: 'absolute', width: '100%', top: Height.header, bottom: fullScreen ? 55 : 0, overflowY: 'scroll' }} sx={{}}>
            <section data-bs-version="5.1" className="header2 cid-sVysVIo9os mbr-parallax-background" id="header2-1">



                <div className="mbr-overlay" style={{ opacity: 0.7, backgroundColor: `rgb(255, 255, 255)` }}></div>

                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <h1 className="mbr-section-title mbr-fonts-style mb-3 display-1"><strong>股票光明燈&nbsp;</strong><strong>0.2版</strong></h1>

                            <p className="mbr-text mbr-fonts-style display-7">股票光明燈找出策略中高勝率／高期望值／高報酬率的股票，提供簡單的進出場燈號，讓投資發揮最大的效益。</p>
                            <div className="mbr-section-btn mt-3"><a className="btn btn-success display-4" onClick={e => props.setMenu(2)}>開始使用</a></div>
                        </div>
                    </div>
                </div>
            </section>
            <Adsense
                client="ca-pub-8613274553211553"
                slot="5061770666"
                style={{ display: 'block' }}
                layout="in-article"
                format="fluid"
            />
            <section data-bs-version="5.1" className="header7 cid-sVAZykzP5e" id="header7-2">





                <div className="text-right container-fluid">
                    <div className="row justify-content-end">
                        <div className="col-12 col-lg-5">
                            <h1 className="mbr-section-title mbr-fonts-style mb-3 display-1"><strong>交易燈</strong></h1>

                            <p className="mbr-text mbr-fonts-style display-7">明確告知何時該暫停交易，等待股市再次回溫。計算出每個策略的每日累積盈虧，只要獲利無法大於２０日的平均盈虧就暫停入場交易，等到超越２０日盈虧，才又開始交易。</p>
                            <div className="mbr-section-btn mt-3"><a className="btn btn-primary display-4" onClick={e => props.setMenu(2)}>立即使用</a></div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-bs-version="5.1" className="header9 cid-sVB2b4rXde" id="header9-4">
                <div className="text-center container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-9">
                            <div className="image-wrap mb-4">
                                <img src="assets/images/mbr-1.jpg" alt="" />
                            </div>
                            <h1 className="mbr-section-title mbr-fonts-style mb-3 display-2"><strong>進場/出場燈</strong></h1>
                            <p className="mbr-text mbr-fonts-style display-4">簡易地找出每個策略中擁有高勝率/高期望值/高報酬率的股票，讓投資者可以更準確的找到投資標的。</p>
                            <div className="mbr-section-btn mt-3"><a className="btn btn-primary display-4" onClick={e => props.setMenu(0)}>立即使用</a></div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-bs-version="5.1" className="info2 cid-sVBhvvHurW" id="info2-5">


                <div className="align-center container-fluid">
                    <div className="row justify-content-center">
                        <div className="card col-12 col-lg-6">
                            <div className="wrapper">
                                <h3 className="mbr-section-title mb-4 mbr-fonts-style display-5"><strong>Android版</strong></h3>

                                <div className="mbr-section-btn"><a className="btn btn-white display-4" target="_blank" href="https://reurl.cc/rQKLLy">下載</a></div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="wrapper">
                                <h3 className="mbr-section-title mbr-fonts-style mb-4 display-5"><strong>iOS版</strong></h3>

                                <div className="mbr-section-btn"><a className="btn btn-white display-4" target="_blank" href="https://reurl.cc/X42rjR">下載</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-bs-version="5.1" className="features6 cid-sVDd3dIVX2" id="features7-7">
                <div className="container">
                    <div className="card-wrapper">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6">
                                <div className="image-wrapper">
                                    <img src="assets/images/product2.jpg" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-lg">
                                <div className="text-box">
                                    <h5 className="mbr-title mbr-fonts-style display-2">
                                        <strong>使用說明書</strong>
                                    </h5>
                                    {/* <p className="mbr-text mbr-fonts-style display-7">2022/3/31止。無使用期限。名額有限。</p>
                                    <div className="cost">
                                        <span className="currentcost mbr-fonts-style pr-2 display-2">$８８８元</span>
                                        <span className="oldcost mbr-fonts-style display-2">$１２８０元</span>
                                    </div> */}
                                    <div className="mbr-section-btn pt-3"><a onClick={e => window.open('stock-candle.pdf')} className="btn btn-primary display-4">下載</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-bs-version="5.1" className="content8 cid-sVDj3BVYtj" id="content8-9">

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="counter-container col-md-12 col-lg-10">
                            <h4 className="mbr-section-title mbr-fonts-style mb-4 display-5">
                                常見問題：</h4>
                            <div className="mbr-text mbr-fonts-style display-7">
                                <ul>
                                    <li><strong>購買後如何收到序號？ </strong>付款完成後，會收到付款完成通知信，商店訂單編號前8碼即是序號。</li><li><strong>早鳥購買的序號有時間限制？</strong> 無使用期限。</li><li><strong>之後策略會增加？</strong>會。</li><li><strong>策略可以自行新增？</strong>不行。</li><li><strong>資料都何時更新？&nbsp;</strong>&nbsp;技術燈：每天下午三點更新。券商燈：每天晚上10點</li>
                                    <li><strong>股價是否即時？&nbsp;</strong>不是。會有３０分鐘的延遲。</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </Box>
    );
}
