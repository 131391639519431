import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';


echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function AvgChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);

    function genoption1() {
        let k = {
            animation:false,
            grid: {
                top: 25,
                left: 0,
                right: 0,
                bottom: 5,
                containLabel:true
            },
            legend: {
                data: ['MA5', 'MA20'],
                right:0,
                icon: 'roundRect',
                formatter: function (name) {
                    let value = result[result.length - 1].s5
                    if(name === 'MA20') {
                        value = result[result.length - 1].s20
                    }
                    return name + " : " + value
                }
                // ...
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: result.map(v => v.x),
            },
            yAxis: {
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                }                
            },
            series: [
                {
                    name: 'MA5',
                    type: 'line',
                    data: result.map(v => v.s5),
                    smooth: true,
                    showSymbol: false,
                },
                {
                    name: 'MA20',
                    type: 'line',
                    data: result.map(v => v.s20),
                    smooth: true,
                    showSymbol: false,
                }
            ]
        };
        return k;
    }

    const fetchData = () => {
        let param = {
            sid: props.row.mmoo.sid
        }

        fetch("/osc/v1/stock2/mmoo/avg", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }

    useEffect(() => {
        if(result != null && result.length > 0) setOption(genoption1)
    }, [result]);

    useEffect(() => {
        // setOption(genoption1)
        if(props.row != null) fetchData()
    }, []);



    return (
        <div style={{padding:1}}  onClick={e => props.openDetail(2, props.menu)}>
            {option != null ?

                <ReactEChartsCore
                    theme={theme.palette.mode}
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={false}
                    style={{ height: 120 }}
                // onEvents={onEvents}
                />
                : null}
        </div>
    );
}

