import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/styles';
import {Height} from '../util/ScreenHeight'
// import ErrorM from '../util/ErrorM'
// import AskDialog from '../util/AskDialog';
// import Photo from '@mui/icons-material/Photo'
// import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import MoreVert from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import BrokerSetting from './BrokerSetting';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -10,
        top: 5,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0
        },
    }

    //  '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
    //     borderRight: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
    //         }`,
    // },
}));


export default function BrokerSelector1(props) {
    const [age, setAge] = React.useState("");
    const [tags, setTags] = React.useState([])
    const [openSetting, setOpenSetting] = React.useState(false);

    const theme = useTheme();
    const classes = useStyles();

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    useEffect(() => {
        if (props.setMenu != null) props.setMenu(age)
    }, [age]);

    useEffect(() => {
        if (tags != null && tags.length > 0) {
            setAge(tags[0].id)
        }
    }, [tags]);


    const fectchTags = () => {
        let u = "/osc/v1/stock2/mmoo/brokermenu"
        
        fetch(u, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setTags(result)
                    }
                }
            );
    }

    useEffect(() => {
        fectchTags()
    }, []);

    const disableBT = () => {
        return age === "0" || age === "1" || age === "2" || age === "3"
    }

    return (
        <Box sx={{ width: '100%', textAlign: 'left', display: 'inline-flex', p: 0, bgcolor: theme.palette.mode === "light" ? "secondary.light" : null }} >
            {openSetting ? <BrokerSetting refresh={props.refresh} open={openSetting} close={setOpenSetting}></BrokerSetting> : null}

            <TextField
                size="small"
                className={classes.root}
                // label="策略"
                sx={{ flexGrow: 1, mr: 0, height: Height.subheader, maxHeight: Height.subheader }}
                select
                value={age}
                onChange={handleChange}
                hiddenLabel
                variant="filled"

            >
                {tags.map(tag => {
                    return <MenuItem key={tag.id} value={tag.id}>
                        {tag.id}{tag.name}
                    </MenuItem>
                })}

            </TextField>
            <Button variant='contained' sx={{ height: Height.subheader - 2, maxHeight: Height.subheader, whiteSpace: 'nowrap', borderRadius: '0px' }} endIcon={<MoreVert />} size="small" color="info" disableElevation onClick={e => setOpenSetting(true)}>設定</Button>
        </Box>
    );
}
