import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Lightbulb } from '@mui/icons-material';
import ProfileChart from './ProfileChart'
import FullProfileChart from './FullProfileChart'

export default function ProfileItem(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const openDetail = () => {
        setOpen(true)
    }

    const clickTitle = () => {
        if (props.goToOSCPage != null) {
            props.goToOSCPage(props.row.s.id)
        }
    }

    const convD = (money) => {
        if(money > 1000000 || money < -1000000) return (money / 1000000).toFixed(2) + "百萬"
        else if(money > 10000 || money < -10000) return (money / 10000).toFixed(2) + "萬"
        else return money
    }

    return (
        <Stack spacing={0} sx={{ m: 1, mb: 0.5, p: 0, border: 2, borderColor: theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main, boxShadow: 0.5, borderRadius: '0px' }}>
            {open ? <FullProfileChart close={setOpen} open={open} id={props.row.s.id} /> : null}

            <Box display={'flex'} onClick={e => clickTitle()}>
                <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 1, pt: 0.5, alignSelf: 'center' }} >
                    <Typography sx={{ textDecoration: "underline", fontSize: 22, alignSelf: 'center' }} >{props.row.s.name}</Typography>

                    {/* <Typography variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} onClick={e => setOpen(true)}>{props.row.mmoo.sid}</Typography> */}
                </Stack>
            </Box>

            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} />


            {props.row.info != null && <Box sx={{ mt: 0.5 }} onClick={e => setOpen(true)}>
                <div>
                    <Typography variant="caption" sx={{ p: 1, alignSelf: 'center' }}>近20日交易</Typography>
                </div>
                <Stack spacing={2} direction={'row'} sx={{ mt: 0.5, justifyContent: 'center' }}>
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>平均持有</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.info.avg_date}天</Typography>
                    </Stack>
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>盈虧增減(元)</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }} color={props.row.info.incm > 0 ? "error" : "inherit"}>{convD(props.row.info.incm)}</Typography>
                    </Stack>
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>盈虧增減%</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }} color={props.row.info.incp > 0 ? "error" : "inherit"}>{props.row.info.incp}</Typography>
                    </Stack>
                    {/* <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>報酬率</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.info.roi}%</Typography>
                    </Stack> */}
                </Stack>
            </Box>}

            {/* <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} variant="middle" /> */}

            <Stack sx={{ m: 1 }} direction={'row'} spacing={0.5}>
                <Button startIcon={<Lightbulb />} color={props.row.light === 0 ? "error" : "inherit"} disableElevation size="small" variant="contained" disabled={props.row.light != 0} >開始交易</Button>
                <Button startIcon={<Lightbulb />} color={props.row.light === 1 ? "success" : "inherit"} disableElevation size="small" variant="contained" disabled={props.row.light != 1} >暫停交易</Button>
            </Stack>

            <ProfileChart row={props.row} openDetail={openDetail} />
        </Stack>

    );
}
