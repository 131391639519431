import React, { useEffect, useRef } from 'react';
import OSCItem from '../osc1/OSCItem'
import { Height } from '../util/ScreenHeight'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { Divider } from '@mui/material';
import { getId } from '../util/ID'
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import AddLoveDialog from './AddLoveDialog';
import Button from '@mui/material/Button';
import AodIcon from '@mui/icons-material/Aod';

export default function LoveContainer() {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(100)

    const [showEmpty, setShowEmpty] = React.useState(false);
    const [emptyMsg, setEmptyMsg] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [openSetting, setOpenSetting] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const fetchData = () => {
        setLoading(true)

        // sn : String, sid : String, tp : Int, froms : Option[String] = None
        let param = {
            sn: getId()
        }

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }


        fetch("/osc/v1/stock2/mmoo/love", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null && result.status === 0) {
                        if (result.data != null && result.data.length >= 0) {
                            setTotal(result.total)
                            setResult(result.data)
                        } else {
                            setTotal(0)
                            setResult(null)
                            setShowEmpty(true)
                            setEmptyMsg("目前尚未找到符合資料。")
                        }
                    }
                }
            );
    }

    useEffect(() => {
        fetchData()
    }, []);

    const updateLove = (sid, tp) => {
        if (sid != null && result != null) {
            let nresult = result.filter(r => {
                return !(r.mmoo.sid === sid && r.love.tp === tp)
            })
            setResult(nresult)
        }
    }

    return (
        <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
            {openSetting ? <AddLoveDialog refresh={fetchData} open={openSetting} close={setOpenSetting}></AddLoveDialog> : null}
            <Button startIcon={<AodIcon></AodIcon>} fullWidth color="inherit" variant="contained"  disableElevation onClick={e => setOpenSetting(true)}>新增自選</Button>
            <Divider />
            
            {loading ? <Box sx={{ position: 'absolute', top: Height.header + Height.subheader-2, width: '100%' }}><LinearProgress color="info" /></Box> : <Grid container spacing={0} sx={{ p: 0.5, width: '100%', bgcolor: 'background.paper', position: 'absolute', top: Height.header  + Height.subheader-2 , bottom: fullScreen ? 100 : 55, overflowY: 'scroll' }}>
                {result != null && result.map(row => {
                    return <Grid key={row.mmoo.sid + ""+ row.love.tp} item xs={12} sm={6} md={4} lg={3}>
                        <OSCItem islove={true} refresh={fetchData} updateLove={updateLove} tp={row.love.tp} key={row.mmoo.sid} row={row} menu={row.love.froms} />
                    </Grid>
                })}
                {result != null && result.length === 0 &&
                    <Typography variant="body1" sx={{ width: '100%', textAlign: 'center', alignSelf: 'center', m: 1 }}>尚未有任何自選股。可以到到進場燈頁面，點選有興趣股票的左上方愛心加入自選股。</Typography>
                }

            </Grid>}

            {<Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
            </Box>}
        </Box>
    );
}
