
export const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const getId = () => {
    if(localStorage.getItem("xuid") == null) {
        let id = uuidv4()
        localStorage.setItem("xuid", id)
        return id
    } else return localStorage.getItem("xuid")
}

export const makeid1 = (length) => {
    var result = '';
    var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const getLic = () => {
    if(localStorage.getItem("lica") == null) {
        let id = makeid1(6)
        localStorage.setItem("lica", id)
        return id
    } else return localStorage.getItem("lica")
}

export const getNoAd = () => {
    return localStorage.getItem('noad') != null ? localStorage.getItem('noad') : "0"
}

